@import url("https://fonts.googleapis.com/css2?family=Grand+Hotel&family=Urbanist:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Urbanist", sans-serif;
}

.emails,
.orders,
.documents,
.invoices {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.ag-theme-alpine .ag-row-hover {
  background-color: #aaf3229f !important;
  /* background-color: rgba(0, #82b81a, 0, 0.3) !important; */
  color: black;
}

.ag-theme-alpine .ag-root-wrapper {
  /* height: 88vh !important; */
}

.ag-theme-alpine .ag-row-selected {
  background-color: #babfc7 !important;
  /* background-color: rgba(0, #82b81a, 0, 0.3) !important; */
  color: black;
}

.progress-bar {
  background-color: #83b81a !important;
}

/* progress-bar progress-bar-animated progress-bar-striped */

.ag-side-button-label {
  margin-left: 0;
}

.ag-icon {
  margin-left: 0 !important;
}
/* span {
  margin-left: 0;
} */
.ag-header-cell-text {
  margin-left: 0 !important;
}

.ag-theme-alpine .ag-checkbox-input-wrapper.ag-checked::after {
  color: #83b81a !important;
}

.ag-theme-alpine .ag-rtl .ag-side-bar-left .ag-selected .ag-side-button-button,
.ag-theme-alpine
  .ag-ltr
  .ag-side-bar-right
  .ag-selected
  .ag-side-button-button {
  border-left-color: #83b81a !important;
}

.ag-theme-alpine .ag-row .ag-cell-data-changed
{
  background-color: #83b81a !important;
}

.ag-theme-alpine .ag-header-cell-menu-button:hover,
.ag-theme-alpine .ag-side-button-button:hover,
.ag-theme-alpine .ag-tab:hover,
.ag-theme-alpine .ag-panel-title-bar-button:hover,
.ag-theme-alpine .ag-header-expand-icon:hover,
.ag-theme-alpine .ag-column-group-icons:hover,
.ag-theme-alpine .ag-group-expanded .ag-icon:hover,
.ag-theme-alpine .ag-group-contracted .ag-icon:hover,
.ag-theme-alpine .ag-chart-settings-prev:hover,
.ag-theme-alpine .ag-chart-settings-next:hover,
.ag-theme-alpine .ag-group-title-bar-icon:hover,
.ag-theme-alpine .ag-column-select-header-icon:hover,
.ag-theme-alpine .ag-floating-filter-button-button:hover,
.ag-theme-alpine .ag-filter-toolpanel-expand:hover,
.ag-theme-alpine .ag-chart-menu-icon:hover {
  color: #83b81a !important;
}
